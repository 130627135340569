import React from "react";
import clsx from "clsx";
import _ from "underscore";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { getFilterData } from "utils/strainService";
import { setTableFilterData, getTableFilterData } from "utils/dataService";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import FlashOnOutlinedIcon from "@material-ui/icons/FlashOnOutlined";
import FilterListIcon from "@material-ui/icons/FilterList";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import Link from "@material-ui/core/Link";
import DialogActions from "@material-ui/core/DialogActions";
import Badge from "@material-ui/core/Badge";
import DialogTitle from "@material-ui/core/DialogTitle";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Menu from "@material-ui/core/Menu";
import Popover from "@material-ui/core/Popover";
import styles from "./styles";

class StrainsTableActions extends React.Component {
  state = {
    actionsAnchorEl: null,
    filtersAnchorEl: null,
    isArchiveDialogOpen: false
  };

  componentDidMount() {
    getFilterData().then(response => {
      let selectedFilters = { show: "" };
      let storedSelectedFilters = getTableFilterData("strainsTable");
      let filters = response.data.data;
      _.each(filters, (item, key) => (selectedFilters[key] = []));
      selectedFilters = storedSelectedFilters || selectedFilters;
      this.setState({
        filterData: filters,
        selectedFilters: selectedFilters
      });
      this.props.onFilterChange && this.props.onFilterChange(selectedFilters);
    });
  }

  openFilterMenu = ({ currentTarget }) => {
    this.setState({
      selectedFilters: _.clone(this.props.selectedFilters),
      filtersAnchorEl: currentTarget
    });
  };

  openArchiveDialog = () => {
    this.setState({
      actionsAnchorEl: null,
      isArchiveDialogOpen: true,
      archiveAction: "archive"
    });
  };

  openUnarchiveDialog = () => {
    this.setState({
      actionsAnchorEl: null,
      isArchiveDialogOpen: true,
      archiveAction: "unarchive"
    });
  };

  handleStrainsArchiveConfirm = () => {
    this.setState({
      isArchiveDialogOpen: false
    });
    if (this.state.archiveAction === "archive")
      this.props.onStrainsArchive && this.props.onStrainsArchive();
    if (this.state.archiveAction === "unarchive")
      this.props.onStrainsUnarchive && this.props.onStrainsUnarchive();
  };

  closeArchiveDialog = () => {
    this.setState({
      isArchiveDialogOpen: false
    });
  };

  handleFilterReset = () => {
    const { filterData } = this.state;
    let selectedFilters = { show: "" };
    _.each(filterData, (item, key) => (selectedFilters[key] = []));
    this.setState({
      selectedFilters: selectedFilters
    });
  };

  handleFilterSubmit = () => {
    this.setState({
      filtersAnchorEl: null
    });
    setTableFilterData("strainsTable", this.state.selectedFilters);
    this.props.onFilterChange &&
      this.props.onFilterChange(this.state.selectedFilters);
  };

  openMenu = anchorElName => ({ currentTarget }) => {
    this.setState({ [anchorElName]: currentTarget });
  };

  closeMenu = anchorElName => () => {
    this.setState({ [anchorElName]: null });
  };

  handleAddClick = () => this.props.history.push("/product-management/product");

  handleFieldChange = filterName => ({ target: { value } }) => {
    const { selectedFilters } = this.state;
    selectedFilters[filterName] = value;
    this.setState({
      selectedFilters
    });
  };

  handleSelectedDelete = (filterName, filterValue) => () => {
    const { selectedFilters } = this.state;
    selectedFilters[filterName] = _.without(
      selectedFilters[filterName],
      filterValue
    );
    this.setState({
      selectedFilters
    });
  };

  render = () => {
    const {
      actionsAnchorEl,
      filtersAnchorEl,
      filterData,
      selectedFilters,
      isArchiveDialogOpen,
      archiveAction
    } = this.state;

    let selectedFiltersCount = 0;
    _.each(this.props.selectedFilters, function(elem, key) {
      return (selectedFiltersCount += _.isArray(elem)
        ? elem.length
        : !!elem
        ? 1
        : 0);
    });

    const { classes, areAnyItemsSelected } = this.props;
    return (
      <>
        <Badge
          color="secondary"
          badgeContent={selectedFiltersCount}
          className={classes.badge}
        >
          <Button
            onClick={this.openFilterMenu}
            variant="outlined"
            size="medium"
            className={classes.button}
          >
            <FilterListIcon className={classes.buttonIcon} />
            {selectedFiltersCount ? "Change" : "Add"} Filters
          </Button>
        </Badge>
        <Popover
          id="filters-menu"
          className={clsx(classes.menu, classes.filtersMenu)}
          anchorEl={filtersAnchorEl}
          keepMounted
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          open={Boolean(filtersAnchorEl)}
          onClose={this.closeMenu("filtersAnchorEl")}
        >
          <Typography variant="h5" id="tableTitle">
            Filters
          </Typography>
          {_.keys(filterData).map(filter => (
            <div key={filter} className={classes.filterHolder}>
              <FormControl fullWidth>
                <InputLabel id={filter}>{filter.toUpperCase()}</InputLabel>
                <Select
                  labelid={filter}
                  multiple
                  MenuProps={{
                    getContentAnchorEl: null,
                    className: classes.selectMenu,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "center"
                    }
                  }}
                  value={selectedFilters[filter]}
                  onChange={this.handleFieldChange(filter)}
                  input={<Input />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selectedFilters[filter].map(value => (
                        <Chip
                          key={value}
                          label={
                            _.findWhere(filterData[filter], { id: value }).name
                          }
                          className={classes.chip}
                          onDelete={this.handleSelectedDelete(filter, value)}
                        />
                      ))}
                    </div>
                  )}
                >
                  {filterData[filter].map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      <Checkbox
                        checked={_.contains(selectedFilters[filter], item.id)}
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ))}
          {selectedFilters && (
            <div className={classes.filterHolder}>
              <FormControl fullWidth>
                <InputLabel shrink id={"show"}>
                  {"Product Status".toUpperCase()}
                </InputLabel>
                <Select
                  displayEmpty
                  labelid={"show"}
                  MenuProps={{
                    getContentAnchorEl: null,
                    className: classes.selectMenu,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "center"
                    }
                  }}
                  value={selectedFilters.show}
                  onChange={this.handleFieldChange("show")}
                >
                  <MenuItem value={""}>All</MenuItem>
                  <MenuItem value={"True"}>Archived</MenuItem>
                  <MenuItem value={"False"}>Unarchived</MenuItem>
                  <MenuItem value={"archivable"}>Archivable</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          <div className={classes.submitHolder}>
            <Link
              component="button"
              variant="body2"
              onClick={this.handleFilterReset}
            >
              Clear Filters
            </Link>
            <Button
              variant="contained"
              onClick={this.handleFilterSubmit}
              color="primary"
            >
              Apply
            </Button>
          </div>
        </Popover>
        <Button
          onClick={this.openMenu("actionsAnchorEl")}
          variant="outlined"
          size="medium"
          disabled={!areAnyItemsSelected}
          className={classes.button}
        >
          <FlashOnOutlinedIcon className={classes.buttonIcon} />
          Actions
        </Button>
        <Menu
          id="actions-menu"
          className={clsx(classes.menu, classes.actionsMenu)}
          anchorEl={actionsAnchorEl}
          keepMounted
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          open={Boolean(actionsAnchorEl)}
          onClose={this.closeMenu("actionsAnchorEl")}
        >
          <MenuItem onClick={this.openArchiveDialog}>
            <ListItemIcon>
              <ArchiveIcon />
            </ListItemIcon>
            <ListItemText primary="Archive" />
          </MenuItem>
          <MenuItem onClick={this.openUnarchiveDialog}>
            <ListItemIcon>
              <UnarchiveIcon />
            </ListItemIcon>
            <ListItemText primary="Unarchive" />
          </MenuItem>
        </Menu>
        <Dialog
          open={isArchiveDialogOpen}
          onClose={this.closeArchiveDialog}
          aria-labelledby="delete-dialog"
        >
          <DialogTitle id="delete-dialog">
            {`Are you sure you want to ${
              archiveAction === "archive" ? "archive" : "unarchive"
            } selected products?`}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={this.closeArchiveDialog}
              color="primary"
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleStrainsArchiveConfirm}
              color="secondary"
              variant="contained"
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Button
          onClick={this.handleAddClick}
          variant="outlined"
          size="medium"
          className={clsx(classes.button, classes.addButton)}
        >
          <AddIcon className={classes.buttonIcon} />
          Add
        </Button>
      </>
    );
  };
}

export default withRouter(withStyles(styles)(StrainsTableActions));

StrainsTableActions.propTypes = {
  onFilterChange: PropTypes.func,
  selectedFilters: PropTypes.object,
  onStrainsArchive: PropTypes.func,
  areAnyItemsSelected: PropTypes.number
};
