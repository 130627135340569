import React from "react";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { logOut } from "utils/loginService";
import { getUserData } from "utils/dataService";
import styles from "./styles";

class ManagementToolbar extends React.Component {
  state = {
    email: getUserData().user.email
  };

  handleLogout = () => {
    logOut();
    this.props.history.push("/");
  };

  render() {
    const { email } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          className={classes.root}
        >
          <div className={classes.profileDropdown}>
            <Button className="profileDropdownButton">
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <span className={classes.marginRight}>{email}</span>
                <UnfoldMoreIcon />
              </Grid>
            </Button>

            <div className="profileDropdownContent" onClick={this.handleLogout}>
              <Button className={classes.logoutButton}>
                <PowerSettingsNewIcon /> Logout
              </Button>
            </div>
          </div>
        </Grid>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ManagementToolbar));
