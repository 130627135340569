import React from "react";
import _ from "underscore";
import { withStyles } from "@material-ui/core/styles";
import ManagementTable from "components/ManagementTable/managementTable";
import OemsTableActions from "containers/OemsTableActions/oemsTableActions";
import WarningIcon from "@material-ui/icons/Warning";
import { getOems } from "utils/oemService";
import { withRouter } from "react-router-dom";
import styles from "./styles";

const rowId = "id";

class OemsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "ID", field: "id" },
        { title: "Name", field: "name" },
        { title: "Company", field: "company" },
        { title: "Country", field: "country" },
        { title: "Province", field: "province" },
        { title: "City", field: "city" },
        { title: "Address", field: "address" },
        { title: "Type", field: "type" }
      ],
      data: {},
      params: {
        searched_string: "",
        page: 1
      },
      selectedItems: [],
      selectedFilters: {}
    };

    this.handleSearchChange = _.debounce(
      this.handleSearchChange.bind(this),
      500
    );
  }

  getData = () => {
    this.setState({ data: {} });
    getOems(_.omit(this.state.params, i => !i)).then(response => {
      this.setState({ data: response.data });
    });
  };

  renderSelectionMessage = selectedItems => {
    const { classes } = this.props;
    const itemArchiveStatus = selectedItems.map(item => item.archived);
    const areDifferentStatusesSelected = _.uniq(itemArchiveStatus).length > 1;

    return (
      <>
        <div>{selectedItems.length} records selected</div>
        {areDifferentStatusesSelected && (
          <div className={classes.warningHolder}>
            <WarningIcon />
            You selected Archived and Unarchived Products
          </div>
        )}
      </>
    );
  };

  handleEditClick = (row, id) =>
    this.props.history.push("/product-management/product/" + id);

  handleSelectionChange = newSelected => {
    this.setState({ selectedItems: newSelected });
  };

  handleStrainsArchive = () => {
    /* const { selectedItems } = this.state;
    const selectedIds = selectedItems.map(item => item[rowId]);

    archiveStrains(selectedIds).then(() => this.getData());
    this.setState({ selectedItems: [] });*/
  };

  handleStrainsUnarchive = () => {
    /* const { selectedItems } = this.state;
    const selectedIds = selectedItems.map(item => item[rowId]);

    unarchiveStrains(selectedIds).then(() => this.getData());
    this.setState({ selectedItems: [] });*/
  };

  handleFilterChange = data => {
    const { params } = this.state;
    _.each(
      data,
      (item, key) =>
        (params[key] = data[key].length ? data[key].join(",") : null)
    );
    this.setState(
      {
        params: params,
        selectedFilters: data
      },
      this.getData
    );
  };

  handleSearchChange = data => {
    const { params } = this.state;
    params.page = 1;
    params.searched_string = data.trim();
    this.setState(
      {
        params: params
      },
      this.getData
    );
  };

  handleChangePage = data => {
    const { params } = this.state;
    params.page = data + 1;
    this.setState(
      {
        params: params
      },
      this.getData
    );
  };

  handleRequestSort = data => {
    const { params } = this.state;
    params.sort = data;

    this.setState(
      {
        params: params
      },
      this.getData
    );
  };

  render() {
    return (
      <ManagementTable
        title="OEM's"
        columns={this.state.columns}
        data={this.state.data}
        selected={this.state.selectedItems}
        rowId={rowId}
        searchText={"Search by name"}
        actions={() => (
          <OemsTableActions
            onFilterChange={this.handleFilterChange}
            selectedFilters={this.state.selectedFilters}
            onStrainsArchive={this.handleStrainsArchive}
            onStrainsUnarchive={this.handleStrainsUnarchive}
            areAnyItemsSelected={this.state.selectedItems.length}
          />
        )}
        onEditClick={this.handleEditClick}
        onSearchChange={this.handleSearchChange}
        onPageChange={this.handlePageChange}
        onChangePage={this.handleChangePage}
        onRequestSort={this.handleRequestSort}
        onSelectionChange={this.handleSelectionChange}
        renderSelectionMessage={this.renderSelectionMessage}
      />
    );
  }
}

export default withRouter(withStyles(styles)(OemsTable));
