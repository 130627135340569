import React from "react";
import _ from "underscore";
import { withStyles } from "@material-ui/core/styles";
import ManagementTable from "components/ManagementTable/managementTable";
import StrainsTableActions from "containers/StrainsTableActions/strainsTableActions";
import WarningIcon from "@material-ui/icons/Warning";
import {
  getStrains,
  archiveStrains,
  unarchiveStrains
} from "utils/strainService";
import { withRouter } from "react-router-dom";
import styles from "./styles";
import moment from "moment";

const rowId = "id";

class StrainsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "ID", field: "id" },
        { title: "Name", field: "name" },
        { title: "LP", field: "lp", render: (data, row) => row.lp_id },
        { title: "Type", field: "type" },
        { title: "Category", field: "category" },
        { title: "THC", field: "thc_content" },
        { title: "CBD", field: "cbd_content" },
        { title: "Batch Id", field: "batch_id" },
        { title: "Purpose", field: "purpose" },
        { title: "Trust Indicator", field: "trust_indicator"},
        {
          title: "Archived",
          field: "archived",
          render: data => (data ? "Yes" : "No")
        },
        {
          title: "Is Archivable",
          field: "is_archivable",
          render: data => (data ? "Yes" : "No")
        },
        {
          title: "Created Date",
          field: "created_date",
          render: data => (data ? moment(data).format("MMM/DD/YYYY") : "")
        }
      ],
      data: {},
      params: {
        name: "",
        page: 1
      },
      selectedItems: [],
      selectedFilters: {}
    };

    this.handleSearchChange = _.debounce(
      this.handleSearchChange.bind(this),
      500
    );
  }

  getData = () => {
    this.setState({ data: {} });
    getStrains(_.omit(this.state.params, i => !i)).then(response => {
      this.setState({ data: response.data });
    });
  };

  renderSelectionMessage = selectedItems => {
    const { classes } = this.props;
    const itemArchiveStatus = selectedItems.map(item => item.archived);
    const areDifferentStatusesSelected = _.uniq(itemArchiveStatus).length > 1;

    return (
      <>
        <div>{selectedItems.length} records selected</div>
        {areDifferentStatusesSelected && (
          <div className={classes.warningHolder}>
            <WarningIcon />
            You selected Archived and Unarchived Products
          </div>
        )}
      </>
    );
  };

  handleEditClick = (row, id) =>
    this.props.history.push("/product-management/product/" + id);

  handleSelectionChange = newSelected => {
    this.setState({ selectedItems: newSelected });
  };

  handleStrainsArchive = () => {
    const { selectedItems } = this.state;
    const selectedIds = selectedItems.map(item => item[rowId]);

    archiveStrains(selectedIds).then(() => this.getData());
    this.setState({ selectedItems: [] });
  };

  handleStrainsUnarchive = () => {
    const { selectedItems } = this.state;
    const selectedIds = selectedItems.map(item => item[rowId]);

    unarchiveStrains(selectedIds).then(() => this.getData());
    this.setState({ selectedItems: [] });
  };

  handleFilterChange = data => {
    const { params } = this.state;
    let { show, ...filters } = data;
    _.each(
      filters,
      (item, key) =>
        (params[key] = data[key].length ? data[key].join(",") : null)
    );
    params.archive = show;
    params.page = 1;
    this.setState(
      {
        params: params,
        selectedFilters: data
      },
      this.getData
    );
  };

  handleSearchChange = data => {
    const { params } = this.state;
    params.page = 1;
    params.name = data.trim();
    this.setState(
      {
        params: params
      },
      this.getData
    );
  };

  handleChangePage = data => {
    const { params } = this.state;
    params.page = data + 1;
    this.setState(
      {
        params: params
      },
      this.getData
    );
  };

  handleRequestSort = data => {
    const { params } = this.state;
    params.sort = data;

    this.setState(
      {
        params: params
      },
      this.getData
    );
  };

  render() {
    return (
      <ManagementTable
        title="Products"
        columns={this.state.columns}
        data={this.state.data}
        selected={this.state.selectedItems}
        rowId={rowId}
        page={this.state.params.page - 1}
        searchText={"Search by name"}
        actions={() => (
          <StrainsTableActions
            onFilterChange={this.handleFilterChange}
            selectedFilters={this.state.selectedFilters}
            onStrainsArchive={this.handleStrainsArchive}
            onStrainsUnarchive={this.handleStrainsUnarchive}
            areAnyItemsSelected={this.state.selectedItems.length}
          />
        )}
        onEditClick={this.handleEditClick}
        onSearchChange={this.handleSearchChange}
        onPageChange={this.handlePageChange}
        onChangePage={this.handleChangePage}
        onRequestSort={this.handleRequestSort}
        onSelectionChange={this.handleSelectionChange}
        renderSelectionMessage={this.renderSelectionMessage}
      />
    );
  }
}

export default withRouter(withStyles(styles)(StrainsTable));
