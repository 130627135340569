import React from "react";
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router
} from "react-router-dom";
import { isUserLoggedIn } from "utils/loginService";
import { appRoutes } from "utils/constants";
import Login from "containers/Login/login";
import Home from "containers/Home/home";
import ResponseErrorSnackbar from "containers/ResponseErrorSnackbar/responseErrorSnackbar";

const PrivateRoute = ({ ...props }) => {
  return isUserLoggedIn() ? <Route {...props} /> : <Redirect to={"/login"} />;
};

class App extends React.Component {
  render() {
    return (
      <>
        <Router>
          <Switch>
            <Route path={"/login"} component={Login} />
            {appRoutes.map(item => (
              <PrivateRoute key={item.path} path={item.path} component={Home} />
            ))}
            <Redirect from="*" to={appRoutes[0].path} />
          </Switch>
          <ResponseErrorSnackbar />
        </Router>
      </>
    );
  }
}

export default App;
