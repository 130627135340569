export const USERDATA = "userData";
export const appRoutes = [
  {
    path: "/product-management",
    title: "Product Management",
    sidebarRoutes: [{ title: "Products", path: "/products", icon: "product" }]
  },
  {
    path: "/oem-management",
    title: "OEM Management",
    sidebarRoutes: [
      { title: "OEM's", path: "/oems", icon: "business" },
      { title: "User Activations", path: "/user-activations", icon: "people" },
      { title: "Permissions", path: "/permissions", icon: "key" },
      { title: "Subscribers", path: "/subscribers", icon: "subscribe" }
    ]
  },
  {
    path: "/user-management",
    title: "User Management"
  }
];
