import React from "react";
import {
  getStrainFieldData,
  getStrain,
  addStrain,
  editStrain,
  getStrainBatches,
  unarchiveStrains,
} from "utils/strainService";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import ManagementSnackbar from "components/ManagementSnackbar/managementSnackbar";
import Button from "@material-ui/core/Button";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import clsx from "clsx";
import CardContent from "@material-ui/core/CardContent";
import styles from "./styles";
import _ from "underscore";
import axios from "axios";

class StrainAddEdit extends React.Component {
  state = {
    batchesData: null,
    formData: null,
    isArchived: false,
    isSnackbarOpen: false,
  };

  loadData() {
    let isEditMode = !!this.props.match.params.id;
    let promises = [getStrainFieldData()];

    if (isEditMode) {
      promises.push(getStrainBatches(this.props.match.params.id));
      promises.push(getStrain(this.props.match.params.id));
    }

    axios.all(promises).then((response) => {
      let { Labels, ...formData } = response[0].data.data;
      let batchesData = isEditMode ? response[1].data.data : [];
      let { archived, ...intialValues } = isEditMode
        ? response[2].data.data
        : {};

      _.mapObject(formData, (section, sectionName) => {
        _.mapObject(section, (sectionItem, sectionItemName) => {
          let value = intialValues[sectionItemName];
          let label = Labels[sectionItemName];

          if (sectionItemName === "country") {
            formData[sectionName][sectionItemName].choices = _.pairs(
              formData[sectionName][sectionItemName].choices
            );
            value = _.first(
              _.map(value, (valueItem) => valueItem.country_of_sale)
            );
          }

          if (sectionItemName === "category") {
            formData[sectionName][sectionItemName].choices = _.map(
              formData[sectionName][sectionItemName].choices,
              (choice) => [choice.id, choice.name]
            );
          }

          if (sectionItemName === "lp_id") {
            formData[sectionName][sectionItemName].choices = _.map(
              formData[sectionName][sectionItemName].choices,
              (choice) => [choice.id, choice.name]
            );
          }

          if (sectionItemName === "province") {
            value = _.map(value, (valueItem) => valueItem.province_of_sale);
          }

          if (sectionItemName === "ratio") {
            formData[sectionName][sectionItemName].choices.unshift(["", "N/A"]);
          }

          formData[sectionName][sectionItemName].value = value || "";
          formData[sectionName][sectionItemName].label =
            label || sectionItemName;
        });
      });

      if (_.contains(formData["Location Availability"].province.value, null)) {
        if (
          formData["Location Availability"].province[
            formData["Location Availability"].country.value
          ]
        )
          formData["Location Availability"].province.value = formData[
            "Location Availability"
          ].province[formData["Location Availability"].country.value].map(
            (item) => item.code
          );
        else {
          formData["Location Availability"].province.value = ["All"];
        }
      }

      this.setState({
        formData: formData,
        batchesData: batchesData,
        isArchived: archived,
        isEditMode,
      });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  handleFieldChange = (sectionName, sectionItemName) => ({
    target: { value },
  }) => {
    const { formData } = this.state;
    formData[sectionName][sectionItemName].value = value;
    if (sectionItemName === "country")
      formData["Location Availability"].province.value = [];

    if (
      (sectionItemName === "province" && _.contains(value, "All")) ||
      (sectionItemName === "country" && (value === "CA" || value === "RSA"))
    )
      formData[
        "Location Availability"
      ].province.value = this.areAllCountryProvincesChecked()
        ? !!formData["Location Availability"].province[
            formData["Location Availability"].country.value
          ]
          ? []
          : ["All"]
        : formData["Location Availability"].province[
            formData["Location Availability"].country.value
          ].map((item) => item.code);

    this.setState({
      formData,
    });
  };

  checkFieldErrors = (sectionName, sectionItemName) => {
    const { formData } = this.state;
    const fieldProperties = formData[sectionName][sectionItemName];

    fieldProperties.errorText = "";
    fieldProperties.error = false;

    if (
      fieldProperties.required &&
      !fieldProperties.read_only &&
      (!fieldProperties.value ||
        (_.isArray(fieldProperties.value) && _.isEmpty(fieldProperties.value)))
    ) {
      fieldProperties.errorText = "Field is required";
      fieldProperties.error = true;
    }

    this.setState({
      formData,
    });
  };

  handleSubmit = () => {
    const { formData, isEditMode } = this.state;
    let data = {
      strain: {},
    };
    let hasErrors = false;

    _.mapObject(formData, (section, sectionName) => {
      _.mapObject(section, (sectionItem, sectionItemName) => {
        if (!!formData[sectionName][sectionItemName].value) {
          data.strain[sectionItemName] =
            formData[sectionName][sectionItemName].value;
        } else {
          data.strain[sectionItemName] = null;
        }

        if (
          sectionItemName === "province" &&
          this.areAllCountryProvincesChecked()
        )
          data.strain[sectionItemName] = ["All"];

        this.checkFieldErrors(sectionName, sectionItemName);
        if (formData[sectionName][sectionItemName].error) hasErrors = true;
      });
    });

    if (!hasErrors) {
      let action = isEditMode
        ? editStrain(this.props.match.params.id, data)
        : addStrain(data);

      action.then((response) => {
        if (!isEditMode) {
          this.props.history.replace(
            `/product-management/product/${response.data.data}`
          );
        }

        this.setState({ isSnackbarOpen: true, formData: null });
        this.loadData();
      });
    }
  };

  closeSnackbar = () => this.setState({ isSnackbarOpen: false });

  handleBatchAddEditClick = (batchId) => {
    let route = `/product-management/product/${this.props.match.params.id}/batch/`;
    if (batchId) route += `${batchId}`;
    this.props.history.push(route);
  };

  handleStrainUnarchiveClick = () => {
    unarchiveStrains([this.props.match.params.id]).then((response) => {
      this.setState({ formData: null });
      this.loadData();
    });
  };

  areAllCountryProvincesChecked = () => {
    const { formData } = this.state;
    let country = formData["Location Availability"].country.value;
    let provinces = formData["Location Availability"].province[country];
    let selectedProvinces = formData["Location Availability"].province.value;

    if (!country) return false;
    if (!provinces) return true;

    const provinceIds = provinces.map((item) => item.code);
    return _.difference(provinceIds, selectedProvinces).length === 0;
  };

  handleBackButtonClick = () => this.props.history.goBack();

  handleFieldRender = (sectionName, sectionItemName) => {
    const { formData } = this.state;
    let fieldProperties = formData[sectionName][sectionItemName];

    switch (true) {
      case sectionItemName === "province":
        let provinces = [];
        if (!!fieldProperties[formData["Location Availability"].country.value])
          provinces =
            fieldProperties[formData["Location Availability"].country.value];
        return (
          <FormControl
            disabled={!provinces || fieldProperties.read_only}
            error={fieldProperties.error}
            fullWidth
          >
            <InputLabel id={sectionItemName}>
              {fieldProperties.label}
            </InputLabel>
            <Select
              labelid={sectionItemName}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
              }}
              multiple
              value={fieldProperties.value}
              onChange={this.handleFieldChange(sectionName, sectionItemName)}
              onBlur={() => this.checkFieldErrors(sectionName, sectionItemName)}
              input={<Input />}
              displayEmpty
              renderValue={(selected) =>
                this.areAllCountryProvincesChecked()
                  ? "All"
                  : selected
                      .map((item) => {
                        return _.findWhere(provinces, { code: item }).name;
                      })
                      .join(", ")
              }
            >
              {(formData["Location Availability"].country.value === "CA" ||
                formData["Location Availability"].country.value === "RSA") && (
                <MenuItem value={"All"}>
                  <Checkbox checked={this.areAllCountryProvincesChecked()} />
                  <ListItemText primary={"All"} />
                </MenuItem>
              )}
              {provinces &&
                provinces.map((province) => (
                  <MenuItem key={province.code} value={province.code}>
                    <Checkbox
                      checked={_.contains(fieldProperties.value, province.code)}
                    />
                    <ListItemText primary={province.name} />
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>{fieldProperties.errorText}</FormHelperText>
          </FormControl>
        );
      case !!fieldProperties.choices && !!fieldProperties.choices.length:
        return (
          <FormControl
            disabled={fieldProperties.read_only}
            error={fieldProperties.error}
            fullWidth
          >
            <InputLabel id={sectionItemName}>
              {fieldProperties.label}
            </InputLabel>
            <Select
              labelid={sectionItemName}
              value={fieldProperties.value}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
              }}
              onBlur={() => this.checkFieldErrors(sectionName, sectionItemName)}
              onChange={this.handleFieldChange(sectionName, sectionItemName)}
            >
              {fieldProperties.choices.map((choice) => (
                <MenuItem key={choice[0]} value={choice[0]}>
                  {choice[1]}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{fieldProperties.errorText}</FormHelperText>
          </FormControl>
        );
      case fieldProperties.type === "TextField":
        return (
          <TextField
            multiline
            fullWidth
            value={fieldProperties.value}
            onBlur={() => this.checkFieldErrors(sectionName, sectionItemName)}
            onChange={this.handleFieldChange(sectionName, sectionItemName)}
            label={fieldProperties.label}
            error={fieldProperties.error}
            helperText={fieldProperties.errorText}
            disabled={fieldProperties.read_only}
          />
        );
      case fieldProperties.type === "CharField":
        return (
          <TextField
            fullWidth
            value={fieldProperties.value}
            onBlur={() => this.checkFieldErrors(sectionName, sectionItemName)}
            onChange={this.handleFieldChange(sectionName, sectionItemName)}
            label={fieldProperties.label}
            inputProps={{
              maxLength: fieldProperties.max_length,
            }}
            error={fieldProperties.error}
            helperText={fieldProperties.errorText}
            disabled={fieldProperties.read_only}
          />
        );
      case fieldProperties.type === "DecimalField":
        return (
          <TextField
            fullWidth
            type="number"
            value={fieldProperties.value}
            onBlur={() => this.checkFieldErrors(sectionName, sectionItemName)}
            onChange={this.handleFieldChange(sectionName, sectionItemName)}
            label={fieldProperties.label}
            inputProps={{
              maxLength: fieldProperties.max_digits,
              step: "0.01",
            }}
            error={fieldProperties.error}
            helperText={fieldProperties.errorText}
            disabled={fieldProperties.read_only}
          />
        );
      case fieldProperties.type === "ForeignKey":
        return (
          <TextField
            fullWidth
            value={fieldProperties.value ? fieldProperties.value : "N/A"}
            onBlur={() => this.checkFieldErrors(sectionName, sectionItemName)}
            onChange={this.handleFieldChange(sectionName, sectionItemName)}
            label={fieldProperties.label}
            error={fieldProperties.error}
            helperText={fieldProperties.errorText}
            disabled={fieldProperties.read_only}
          />
        );
      default:
        return <div>{fieldProperties.label}</div>;
    }
  };

  render() {
    const { classes } = this.props;
    const { formData, batchesData, isArchived, isEditMode } = this.state;
    return (
      <>
        {!formData ? (
          <div className={classes.loaderWrapper}>
            <CircularProgress size={100} />
          </div>
        ) : (
          <div className={classes.root}>
            <div className={classes.titleHolder}>
              <IconButton
                onClick={this.handleBackButtonClick}
                className={classes.backButton}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <Typography className={classes.title} variant="h5">
                {(isEditMode ? "Edit" : "Add") + " Product"}
              </Typography>
            </div>
            <Grid container className={classes.container}>
              <Grid item md={6} xs={12}>
                <form noValidate autoComplete="off">
                  {_.keys(formData).map((sectionName) => (
                    <div key={sectionName} className={classes.section}>
                      <div className={classes.sectionTitle}>
                        <Typography variant="h6">{sectionName}</Typography>
                      </div>
                      <Grid
                        key={sectionName}
                        container
                        className={classes.sectionContainer}
                        spacing={4}
                      >
                        {_.keys(formData[sectionName]).map(
                          (sectionItemName) => (
                            <Grid item xs={6} key={sectionItemName}>
                              {this.handleFieldRender(
                                sectionName,
                                sectionItemName
                              )}
                            </Grid>
                          )
                        )}
                      </Grid>
                    </div>
                  ))}
                  <Grid
                    container
                    justify="flex-end"
                    spacing={4}
                    className={classes.submitHolder}
                  >
                    <div>
                      <Button
                        variant="contained"
                        onClick={this.handleSubmit}
                        color="primary"
                      >
                        Submit
                      </Button>
                    </div>
                  </Grid>
                </form>
              </Grid>
              <Grid item md={6} xs={12}>
                <Grid
                  justify="space-between"
                  alignItems="center"
                  className={classes.batchesTitle}
                  container
                >
                  <Typography variant="h5">Batches</Typography>
                  {!isArchived && isEditMode && (
                    <Button
                      onClick={() => this.handleBatchAddEditClick()}
                      variant="outlined"
                      size="medium"
                      className={clsx(classes.button, classes.addButton)}
                    >
                      <AddIcon className={classes.buttonIcon} />
                      Add
                    </Button>
                  )}
                  {isArchived && isEditMode && (
                    <Button
                      onClick={() => this.handleStrainUnarchiveClick()}
                      variant="outlined"
                      size="medium"
                      className={classes.button}
                    >
                      <UnarchiveIcon className={classes.buttonIcon} />
                      Unarchive
                    </Button>
                  )}
                </Grid>
                <Grid
                  className={classes.sectionContainer}
                  spacing={4}
                  container
                >
                  {batchesData.map((batch) => (
                    <Grid item key={batch.id} xs={6}>
                      <Card>
                        <CardContent>
                          <Grid
                            justify="space-between"
                            alignItems="center"
                            className={classes.cardTitle}
                            container
                          >
                            <Typography variant="h6">
                              Batch {batch.batch_number}#
                            </Typography>
                            {!isArchived && (
                              <IconButton
                                onClick={() =>
                                  this.handleBatchAddEditClick(batch.id)
                                }
                                className={classes.editBatchButton}
                              >
                                <EditIcon />
                              </IconButton>
                            )}
                          </Grid>
                          <Typography variant="subtitle2">
                            CBD Content: <b>{batch.cbd_content}</b>
                          </Typography>
                          <Typography variant="subtitle2">
                            THC Content: <b>{batch.thc_content}</b>
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
        <ManagementSnackbar
          variant="success"
          message={"Product saved successfully!"}
          open={this.state.isSnackbarOpen}
          handleClose={this.closeSnackbar}
        />
      </>
    );
  }
}

export default withRouter(withStyles(styles)(StrainAddEdit));
