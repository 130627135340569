import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import ManagementToolbar from "components/ManagementToolbar/managementToolbar";
import StrainsTable from "containers/StrainsTable/strainsTable";
import BatchAddEdit from "containers/BatchAddEdit/batchAddEdit";
import StrainAddEdit from "containers/StrainAddEdit/strainAddEdit";
import ManagementSidebar from "components/ManagementSidebar/managementSidebar";
import styles from "./styles";
import OemsTable from "containers/OemsTable/oemsTable";

class Home extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <div className={classes.sidebar}>
          <ManagementSidebar />
        </div>
        <div className={classes.contentHolder}>
          <div className={classes.toolbar}>
            <ManagementToolbar />
          </div>
          <div className={classes.content}>
            <Switch>
              <Route
                exact
                path={`/product-management`}
                render={() => <Redirect to="/product-management/products" />}
              />
              <Route
                exact
                path={`/product-management/products`}
                component={StrainsTable}
              />
              <Route
                exact
                path={`/product-management/product/:id/batch/:batchId?`}
                component={BatchAddEdit}
              />
              <Route
                exact
                path={`/product-management/product/:id?`}
                component={StrainAddEdit}
              />
              <Route
                exact
                path={`/oem-management/oems`}
                component={OemsTable}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Home);
