import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import SpaIcon from "@material-ui/icons/Spa";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import PeopleIcon from "@material-ui/icons/People";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import BusinessIcon from "@material-ui/icons/Business";
import { appRoutes } from "utils/constants";
import _ from "underscore";
import styles from "./styles";

const icons = {
  product: <SpaIcon />,
  key: <VpnKeyIcon />,
  people: <PeopleIcon />,
  subscribe: <SubscriptionsIcon />,
  business: <BusinessIcon />
};

class ManagementSidebar extends React.Component {
  state = {
    appMenuAnchorEl: null
  };

  closeAppMenu = () => {
    this.setState({ appMenuAnchorEl: null });
  };

  openAppMenu = ({ currentTarget }) => {
    this.setState({ appMenuAnchorEl: currentTarget });
  };

  render() {
    const { appMenuAnchorEl } = this.state;
    const { classes, match } = this.props;

    const currentRoute = _.findWhere(appRoutes, {
      path: match.path
    });
    const otherRoutes = _.without(appRoutes, currentRoute);

    return (
      <div className={classes.root}>
        <Button
          size="large"
          onClick={this.openAppMenu}
          className={classes.appSwitchButton}
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <img
                  src={process.env.PUBLIC_URL + "/logo.png"}
                  alt="Strainprint logo"
                  className={classes.logo}
                />
                <span className={classes.logoText}>{currentRoute.title}</span>
              </Grid>
            </Grid>
            <UnfoldMoreIcon className={classes.icon} />
          </Grid>
        </Button>
        <Menu
          id="app-select-menu"
          className={classes.menu}
          anchorEl={appMenuAnchorEl}
          keepMounted
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          open={Boolean(appMenuAnchorEl)}
          onClose={this.closeAppMenu}
        >
          {otherRoutes.map(item => (
            <MenuItem key={item.path} component={NavLink} to={item.path}>
              <ListItemText
                className={classes.appMenuText}
                primary={item.title}
              />
            </MenuItem>
          ))}
        </Menu>
        <List className={classes.nav}>
          {currentRoute.sidebarRoutes &&
            currentRoute.sidebarRoutes.map(item => (
              <ListItem
                key={item.path}
                component={NavLink}
                to={match.path + item.path}
                activeClassName="active"
                className={classes.navItem}
                button
              >
                <ListItemIcon>{icons[item.icon]}</ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItem>
            ))}
        </List>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ManagementSidebar));
