import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import styles from "./styles";
import { Redirect } from "react-router-dom";
import { logIn, isUserLoggedIn } from "utils/loginService";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: isUserLoggedIn(),
      email: "",
      password: ""
    };
  }

  handleLogin = e => {
    e.preventDefault();
    let { email, password } = this.state;
    logIn(email, password).then(() => {
      this.props.history.push("");
    });
  };

  handleInputChange = e => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({
      [name]: value
    });
  };

  render() {
    const { classes } = this.props;
    const { email, password, isLoggedIn } = this.state;

    return isLoggedIn ? (
      <Redirect to="/" />
    ) : (
      <Container component="div" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <img
              src={process.env.PUBLIC_URL + "/logo.png"}
              alt="Strainprint logo"
              className={classes.logo}
            />
          </Avatar>
          <form className={classes.form} onSubmit={this.handleLogin} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={this.handleInputChange}
              value={email}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={this.handleInputChange}
              value={password}
              autoComplete="current-password"
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submit}
            >
              Sign In
            </Button>
          </form>
        </div>
      </Container>
    );
  }
}

export default withRouter(withStyles(styles)(Login));
