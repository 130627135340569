import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import SearchIcon from "@material-ui/icons/Search";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { ToolbarStyles } from "./styles";
import Grid from "@material-ui/core/Grid";

class ManagementTableToolbar extends React.Component {
  render() {
    const {
      selected,
      title,
      classes,
      actions,
      onSearchChange,
      searchText,
      renderSelectionMessage
    } = this.props;

    return (
      <Toolbar className={classes.root}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <div className={classes.titleHolder}>
            {selected.length > 0 ? (
              <Typography
                className={classes.title}
                color="inherit"
                variant="subtitle1"
              >
                {!!renderSelectionMessage ? (
                  renderSelectionMessage(selected)
                ) : (
                  <div>{selected.length} records selected</div>
                )}
              </Typography>
            ) : (
              <Typography
                className={classes.title}
                variant="h5"
                id="tableTitle"
              >
                {title}
              </Typography>
            )}
          </div>

          <div className={classes.actionsHolder}>
            <Grid
              container
              item
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <div className={classes.search}>
                <IconButton className={classes.iconButton} aria-label="menu">
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} orientation="vertical" />
                <InputBase
                  className={classes.input}
                  onChange={e => onSearchChange(e.target.value)}
                  placeholder="Search"
                  inputProps={{
                    "aria-label": "search",
                    placeholder: searchText ? searchText : "Search"
                  }}
                />
              </div>
              <div>{actions && actions()}</div>
            </Grid>
          </div>
        </Grid>
      </Toolbar>
    );
  }
}

export default withStyles(ToolbarStyles)(ManagementTableToolbar);

ManagementTableToolbar.propTypes = {
  selected: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  actions: PropTypes.func,
  renderSelectionMessage: PropTypes.func
};
