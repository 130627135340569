import React from "react";
import ManagementSnackbar from "components/ManagementSnackbar/managementSnackbar";
import { instance, removeUserData } from "utils/dataService";
import { withRouter } from "react-router-dom";

class ResponseErrorSnackbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      message: ""
    };

    instance.interceptors.response.use(
      response => response,
      error => {
        //unauthorized
        if (error.response.status === 401) {
          removeUserData();
          this.props.history.push("");
        }
        this.setState({
          open: true,
          message: `${error.response.status} : ${error.response.statusText}`
        });
        return Promise.reject(error);
      }
    );
  }

  closeSnackbar = () => this.setState({ open: false });

  render() {
    const { message, open } = this.state;
    return (
      <ManagementSnackbar
        variant="error"
        message={message}
        open={open}
        handleClose={this.closeSnackbar}
      />
    );
  }
}

export default withRouter(ResponseErrorSnackbar);
