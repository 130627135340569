export const ToolbarStyles = theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: "100px"
  },
  iconButton: {
    padding: "10px"
  },
  title: {
    minHeight: "32px",
    fontWeight: "bold"
  },
  input: {
    width: "100%"
  },
  titleHolder: {
    [theme.breakpoints.down(1200)]: {
      width: "100%",
      paddingTop: "20px",
      textAlign: "center"
    }
  },
  actionsHolder: {
    [theme.breakpoints.down(1200)]: {
      width: "100%",
      padding: "20px 0"
    }
  },
  search: {
    backgroundColor: "white",
    border: "1px solid #31D7C3",
    borderRadius: "40px",
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(2),
    [theme.breakpoints.down(1200)]: {
      width: "100%",
      margin: "0 0 20px 0"
    }
  }
});

export const TableStyles = theme => ({
  table: {
    backgroundColor: "white",
    minWidth: "1300px",
    height: "1px"
  },
  tableRow: {
    height: "100%"
  },
  tableCell: {
    cursor: "pointer",
    padding: "0",
    paddingRight: "10px",
    height: "100%",
    fontSize: "16px",
    "&:first-of-type, &:last-of-type": {
      minWidth: "initial"
    }
  },
  pagination: {
    backgroundColor: "white"
  },
  tableWrapper: {
    height: "calc(100vh - 216px)",
    overflowX: "auto",
    [theme.breakpoints.down(1200)]: {
      height: "calc(100vh - 322px)"
    },
    [theme.breakpoints.down(960)]: {
      height: "calc(100vh - 337px)"
    }
  },
  loaderWrapper: {
    overflow: "hidden",
    position: "absolute",
    width: "calc(100% - 200px)",
    paddingLeft: "calc(50% - 100px)",
    paddingTop: "200px",
    height: "calc(100vh - 233px)",
    [theme.breakpoints.down(1200)]: {
      height: "calc(100vh - 339px)"
    },
    [theme.breakpoints.down(960)]: {
      height: "calc(100vh - 355px)"
    },
    "& .MuiCircularProgress-svg": {
      color: "#1BB3A1"
    }
  },
  cellIdHighlight: {
    backgroundColor: "rgba(27, 179, 161, 0.15)"
  },
  cellContent: {
    height: "100%",
    width: "100%",
    padding: "5px 10px",
    marginRight: "5px",
    alignItems: "center",
    display: "flex"
  },
  checkboxCellContent: {
    justifyContent: "center",
    position: "relative",
    right: "4px"
  }
});

export const TableHeadStyles = theme => ({
  tableCell: {
    cursor: "pointer",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    borderBottom: "2px solid rgb(224, 224, 224)",
    borderTop: "2px solid rgb(224, 224, 224)",
    backgroundColor: "white",
    padding: "0px 10px",
    fontSize: "16px",
    "&:first-of-type, &:last-of-type": {
      borderBottom: "2px solid rgb(224, 224, 224)"
    },
    "&:first-of-type span": {
      padding: "8px 5px"
    }
  },
  tableCellContent: {
    paddingRight: "70px",
    borderRight: "2px solid rgba(224, 224, 224, 1)"
  },
  arrowWrapper: {
    position: "relative",
    left: "65px",
    marginLeft: "-24px"
  },
  arrowUp: {
    position: "relative",
    marginTop: "-12px",
    top: "12px"
  },
  hidden: {
    visibility: "hidden"
  }
});
