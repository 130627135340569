export default theme => ({
  warningHolder: {
    display: "flex",
    fontSize: "12px",
    "& svg": {
      fontSize: "18px",
      color: "#ffa000",
      marginRight: "5px"
    }
  }
});
