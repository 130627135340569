export default theme => ({
  titleHolder: {
    display: "flex",
    alignItems: "center",
    minHeight: "100px",
    padding: "0 16px",
    [theme.breakpoints.down(1200)]: {
      width: "100%",
      textAlign: "center"
    }
  },
  title: {
    height: "32px",
    fontWeight: "bold"
  },
  container: {
    backgroundColor: "white"
  },
  sectionContainer: {
    width: "100%",
    margin: 0,
    "& .MuiSelect-root": {
      height: "19px"
    }
  },
  submitHolder: {
    backgroundColor: "white",
    margin: 0,
    width: "100%",
    padding: "16px"
  },
  backButton: {
    marginRight: "10px",
    "& svg": {
      position: "relative",
      left: "4px"
    }
  },
  loaderWrapper: {
    position: "relative",
    left: "calc(50% - 50px)",
    top: "100px",
    "& .MuiCircularProgress-svg": {
      color: "#1BB3A1"
    }
  },
  editBatchButton: {
    padding: 0
  },
  cardTitle: {
    "& h6": {
      fontWeight: "bold"
    },
    marginBottom: "10px"
  },
  button: {
    backgroundColor: "white",
    padding: "8px",
    borderRadius: "5px"
  },
  addButton: {
    color: "white",
    backgroundColor: "green",
    borderColor: "black",
    "&:hover": {
      backgroundColor: "rgba(0,128,0,0.9)"
    }
  },
  batchesTitle: {
    padding: "16px",
    "& h5": {
      fontWeight: "bold"
    }
  },
  sectionTitle: {
    padding: "10px",
    "& h6": {
      fontWeight: "bold"
    }
  },
  section: {
    padding: "10px 0"
  }
});
