export default theme => ({
  nav: {
    padding: 0,
    marginTop: "10px"
  },
  navItem: {
    padding: "5px 25px",
    "&.active": {
      backgroundColor: "#0b4740"
    },
    "&.active:after": {
      content: "''",
      position: "relative",
      display: "block",
      left: "26px",
      padding: "0",
      height: "0",
      width: "0",
      float: "right",
      backgroundColor: "transparent",
      borderTop: "20.2px solid transparent",
      borderBottom: "20.2px solid transparent",
      borderRight: "9px solid #ebebf2"
    },
    "& span": {
      fontSize: "19px"
    },
    "& svg": {
      color: "white"
    }
  },
  appSwitchButton: {
    backgroundColor: "white",
    width: "180px",
    height: "calc(100% - 10px)",
    margin: "5px 10px",
    padding: "2px",

    "&:hover": {
      backgroundColor: "white"
    }
  },
  logo: {
    margin: "2px",
    width: "40px",
    height: "40px"
  },
  logoText: {
    color: "#1BB3A1",
    width: "92px",
    marginLeft: theme.spacing(1),
    fontSize: "12px",
    fontWeight: "bold",
    textAlign: "left",
    lineHeight: "12px"
  },
  icon: {
    color: "#1BB3A1"
  },
  menu: {
    "& .MuiPopover-paper": {
      border: "1px solid #1BB3A1",
      position: "relative",
      marginTop: "2px",
      width: "172px"
    },
    "& .MuiList-root": {
      padding: "0"
    },
    "& .MuiMenuItem-root": {
      padding: "7px 11px",
      textAlign: "center"
    },
    "& .MuiTypography-root": {
      color: "#1BB3A1",
      fontWeight: "bold",
      textTransform: "uppercase",
      fontSize: "12px"
    }
  }
});
