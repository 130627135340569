import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import _ from "underscore";
import styles from "./styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";

class ManagementAddEditForm extends React.Component {
  state = {
    currentTab: 0,
    hasErrors: false,
  };

  handleBackButtonClick = () => this.props.history.goBack();

  handleTabChange = (event, newValue) => {
    const { hasErrors } = this.state;

    if(!hasErrors)
    this.setState({ currentTab: newValue });
  };

  checkFieldErrors = () => {    
    const { checkFieldErrors, formData } = this.props;
    
    if (!!checkFieldErrors) {
      let hasErrors = false;

      _.mapObject(formData, (section, sectionName) => {
        _.mapObject(section, (sectionItem, sectionItemName) => {
          checkFieldErrors(sectionName, sectionItemName);
          if (formData[sectionName][sectionItemName].error) hasErrors = true;
        });
      });
 
      this.setState({hasErrors: hasErrors});
    }
  }

  handleSubmit = () => {
    const { hasErrors } = this.state;
    const { onSubmit } = this.props;

    this.checkFieldErrors();

    if (!hasErrors) onSubmit();
  };

  renderFieldData = (sectionName, sectionItemName) => {
    const { onFieldChange, onFieldRender, formData } = this.props;
    let fieldProperties = formData[sectionName][sectionItemName];

    let overridenField =
      onFieldRender &&
      onFieldRender(sectionName, sectionItemName, fieldProperties);
    if (overridenField) return overridenField;
    switch (true) {
      case !!fieldProperties.choices && !!fieldProperties.choices.length:
        return (
          <FormControl error={fieldProperties.error} fullWidth>
            <InputLabel id={sectionItemName}>
              {fieldProperties.label}
            </InputLabel>
            <Select
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }}
              disabled={fieldProperties.read_only}
              labelid={sectionItemName}
              value={fieldProperties.value}
              onChange={onFieldChange(sectionName, sectionItemName)}
              onBlur={this.checkFieldErrors}
            >
              {fieldProperties.choices.map(choice => (
                <MenuItem key={choice[0]} value={choice[0]}>
                  {choice[1]}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{fieldProperties.errorText}</FormHelperText>
          </FormControl>
        );
      case fieldProperties.type === "TextField":
        return (
          <TextField
            multiline
            fullWidth
            disabled={fieldProperties.read_only}
            value={fieldProperties.value}
            onChange={onFieldChange(sectionName, sectionItemName)}
            onBlur={this.checkFieldErrors}
            label={fieldProperties.label}
            error={fieldProperties.error}
            helperText={fieldProperties.errorText}
          />
        );
      case fieldProperties.type === "CharField":
        return (
          <TextField
            fullWidth
            disabled={fieldProperties.read_only}
            value={fieldProperties.value}
            onChange={onFieldChange(sectionName, sectionItemName)}
            onBlur={this.checkFieldErrors}
            label={fieldProperties.label}
            inputProps={{ maxLength: fieldProperties.max_length }}
            error={fieldProperties.error}
            helperText={fieldProperties.errorText}
          />
        );
      case fieldProperties.type === "DecimalField":
        return (
          <TextField
            fullWidth
            type="number"
            disabled={fieldProperties.read_only}
            value={fieldProperties.value}
            onChange={onFieldChange(sectionName, sectionItemName)}
            onBlur={this.checkFieldErrors}
            label={fieldProperties.label}
            inputProps={{ maxLength: fieldProperties.max_digits, step: "0.01" }}
            error={fieldProperties.error}
            helperText={fieldProperties.errorText}
          />
        );
      default:
        return <div>{fieldProperties.label}</div>;
    }
  };

  renderFields(sectionName) {
    const { classes, formData, subcategories } = this.props;

    if (!subcategories || !subcategories[sectionName])
      return _.keys(formData[sectionName]).map(sectionItemName => (
        <Grid item xs={6} key={sectionItemName}>
          {this.renderFieldData(sectionName, sectionItemName)}
        </Grid>
      ));

    return _.keys(subcategories[sectionName]).map(subcategoryName => (
      <React.Fragment key={subcategoryName}>
        <div className={classes.subcategoryTitle}>
          <Typography variant="h6">{subcategoryName}</Typography>
        </div>
        {subcategories[sectionName][subcategoryName].map(sectionItemName => (
          <Grid item xs={6} key={sectionItemName}>
            {this.renderFieldData(sectionName, sectionItemName)}
          </Grid>
        ))}
      </React.Fragment>
    ));
  }

  render() {
    const { title, classes, formData } = this.props;
    const { currentTab } = this.state;
    return !formData ? (
      <div className={classes.loaderWrapper}>
        <CircularProgress size={100} />
      </div>
    ) : (
      <div className={classes.root}>
        <div className={classes.titleHolder}>
          <IconButton
            onClick={this.handleBackButtonClick}
            className={classes.backButton}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography className={classes.title} variant="h5">
            {title}
          </Typography>
        </div>
        <AppBar position="static" color="default">
          <Tabs
            value={currentTab}
            className={classes.tabs}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            variant="fullWidth"
            scrollButtons="auto"
            TabIndicatorProps={{
              style: {
                backgroundColor: "white"
              }
            }}
            aria-label="scrollable tabs"
          >
            {_.keys(formData).map((sectionName, index) => (
              <Tab
                key={index}
                label={sectionName}
                id={`scrollable-tab-${index}`}
                aria-controls={`scrollable-tabpanel-${index}`}
              />
            ))}
          </Tabs>
        </AppBar>
        <form noValidate autoComplete="off">
          {_.keys(formData).map((sectionName, index) => (
            <div
              key={sectionName}
              role="tabpanel"
              hidden={currentTab !== index}
              id={`scrollable-tabpanel-${index}`}
              aria-labelledby={`scrollable-tab-${index}`}
            >
              {currentTab === index && (
                <div className={classes.container}>
                  <Grid container spacing={4} className={classes.formContainer}>
                    {this.renderFields(sectionName)}
                  </Grid>
                </div>
              )}
              <Grid
                container
                justify="flex-end"
                spacing={4}
                className={classes.submitHolder}
              >
                <div>
                  <Button
                    variant="contained"
                    onClick={this.handleSubmit}
                    color="primary"
                  >
                    Submit
                  </Button>
                </div>
              </Grid>
            </div>
          ))}
        </form>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ManagementAddEditForm));

ManagementAddEditForm.propTypes = {
  title: PropTypes.string.isRequired,
  formData: PropTypes.object,
  onFieldRender: PropTypes.func,
  onFieldChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  checkFieldErrors: PropTypes.func,
  subcategories: PropTypes.object
};
