export default theme => ({
  root: {
    display: "flex",
    minWidth: "960px"
  },
  toolbar: {
    backgroundColor: "white"
  },
  contentHolder: {
    width: "calc(100% - 200px)"
  },
  content: {
    backgroundColor: "#ebebf2",
    minHeight: "calc(100vh - 63px)",

    [theme.breakpoints.down(960)]: {
      minHeight: "calc(100vh - 80px)"
    }
  },
  sidebar: {
    color: "white",
    background: "linear-gradient(to bottom, #17685f 0%, #229b91 100%)",
    boxSizing: "border-box",
    borderRight: "1px solid #31D7C3",
    width: "200px"
  }
});
