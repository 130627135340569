import axios from "axios";
import { USERDATA } from "utils/constants";

let instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT
});

injectAuthorization();

function injectAuthorization() {
  let userData = getUserData();
  if (userData && userData.token) {
    instance.defaults.headers.common["Authorization"] = userData.token;
  }
}

function setUserData(loginResponse) {
  const { token, user } = loginResponse;
  localStorage.setItem(
    USERDATA,
    JSON.stringify({
      token: "Bearer " + token,
      user: user
    })
  );

  injectAuthorization();
}

function getUserData() {
  return JSON.parse(localStorage.getItem(USERDATA));
}

function removeUserData() {
  localStorage.removeItem(USERDATA);
}

function setTableFilterData(tableName, selectedFilters) {
  localStorage.setItem(tableName, JSON.stringify(selectedFilters));
}

function getTableFilterData(tableName) {
  return JSON.parse(localStorage.getItem(tableName));
}

export {
  instance,
  setUserData,
  getUserData,
  removeUserData,
  setTableFilterData,
  getTableFilterData
};
