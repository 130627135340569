export default theme => ({
  button: {
    backgroundColor: "white",
    padding: "11px",
    borderRadius: "5px",
    marginLeft: "10px"
  },
  addButton: {
    color: "white",
    backgroundColor: "green",
    borderColor: "black",
    "&:hover": {
      backgroundColor: "rgba(0,128,0,0.9)"
    }
  },
  buttonIcon: {
    marginRight: "8px"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  menu: {
    "& .MuiPopover-paper": {
      border: "1px solid #1BB3A1",
      position: "relative",
      marginTop: "5px"
    },
    "& .MuiList-root": {
      padding: "0"
    }
  },
  actionsMenu: {
    "& .MuiPopover-paper": {
      width: "140px"
    },
    "& .MuiMenuItem-root": {
      padding: "7px 11px"
    },
    "& .MuiTypography-root": {
      textTransform: "uppercase",
      fontSize: "0.875rem"
    },
    "& .MuiListItemIcon-root": {
      minWidth: "initial",
      marginRight: "5px"
    },
    "& svg": {
      color: "black"
    }
  },
  filtersMenu: {
    "& .MuiPopover-paper": {
      width: "400px",
      padding: "15px"
    }
  },
  filterHolder: {
    padding: "15px 0"
  },
  submitHolder: {
    padding: "15px 0",
    display: "flex",
    justifyContent: "space-between"
  },
  selectMenu: {
    "& .MuiPaper-root": {
      maxHeight: "300px",
      maxWidth: "360px"
    }
  }
});
