import {
  instance,
  setUserData,
  getUserData,
  removeUserData
} from "utils/dataService";

function logIn(email, password) {
  return instance
    .post("/login/", {
      email: email,
      password: password
    })
    .then(response => {
      setUserData(response.data);
    });
}

function logOut() {
  removeUserData();
}

function isUserLoggedIn() {
  return !!getUserData();
}

export { logIn, logOut, isUserLoggedIn };
