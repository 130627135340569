export default theme => ({
  tabs: {
    color: "white",
    backgroundColor: "#1BB3A1"
  },
  titleHolder: {
    display: "flex",
    alignItems: "center",
    minHeight: "100px",
    padding: "0 16px",
    [theme.breakpoints.down(1200)]: {
      width: "100%",
      textAlign: "center"
    }
  },
  title: {
    height: "32px",
    fontWeight: "bold"
  },
  container: {
    height: "calc(100vh - 280px)",
    backgroundColor: "white"
  },
  formContainer: {
    height:"100%",
    overflow: "auto",
    width: "100%",
    margin: 0,
    "& .MuiSelect-root": {
      height: "19px"
    }
  },

  submitHolder: {
    backgroundColor: "white",
    margin: 0,
    width: "100%",
    padding: "16px"
  },
  backButton: {
    marginRight: "10px",
    "& svg": {
      position: "relative",
      left: "4px"
    }
  },
  loaderWrapper: {
    position: "relative",
    left: "calc(50% - 50px)",
    top: "100px",
    "& .MuiCircularProgress-svg": {
      color: "#1BB3A1"
    }
  },
  subcategoryTitle: {
    padding: "0 10px",
    width: "100%",
    marginTop: "16px",
    "& h6": {
      fontWeight: "bold"
    }
  }
});
