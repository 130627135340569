import { instance } from "utils/dataService";

function getOems(params = {}) {
  return instance.get(`/oems/oem-list/`, { params: params });
}

function getFilterData() {
  return instance.get(`/filters/oem`);
}

export { getOems, getFilterData };
