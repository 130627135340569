import React from "react";
import {
  getBatchFieldData,
  getBatch,
  addBatch,
  editBatch
} from "utils/strainService";
import ManagementAddEditForm from "components/ManagementAddEditForm/managementAddEditForm";
import ManagementSnackbar from "components/ManagementSnackbar/managementSnackbar";
import _ from "underscore";
import axios from "axios";

class BatchAddEdit extends React.Component {
  state = {
    formData: null,
    isSnackbarOpen: false
  };

  loadData() {
    let isEditMode = !!this.props.match.params.batchId;
    let promises = [getBatchFieldData()];

    if (isEditMode) {
      promises.push(
        getBatch(this.props.match.params.id, this.props.match.params.batchId)
      );
    }

    axios.all(promises).then(response => {
      let { Labels, ...formData } = response[0].data.data;

      let { batch_number, ...intialValues } = isEditMode
        ? response[1].data.data
        : {};

      _.mapObject(formData, (section, sectionName) => {
        _.mapObject(section, (sectionItem, sectionItemName) => {
          formData[sectionName][sectionItemName].value =
            intialValues[sectionItemName] || "";
          formData[sectionName][sectionItemName].label =
            Labels[sectionItemName] || sectionItemName;
        });
      });

      let subcategories = this.constructSubcategories(formData);

      this.setState({
        formData: formData,
        subcategories,
        isEditMode,
        batch_number
      });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  constructSubcategories(formData) {
    const { Terpenes, Pricing, ...restOfData } = formData;
    let subcategories = {};

    _.mapObject(restOfData, (section, sectionName) => {
      subcategories[sectionName] = {};
      _.mapObject(section, (sectionItem, sectionItemName) => {
        let subcategory = sectionItemName.split("_")[0].toUpperCase();
        if (!subcategories[sectionName][subcategory])
          subcategories[sectionName][subcategory] = [];
        subcategories[sectionName][subcategory].push(sectionItemName);
      });
    });

    return subcategories;
  }

  handleFieldChange = (sectionName, sectionItemName) => ({
    target: { value }
  }) => {
    const { formData } = this.state;
    formData[sectionName][sectionItemName].value = value;

    this.setState({
      formData
    });
  };

  handleSubmit = () => {
    const { formData, isEditMode } = this.state;
    let data = { batch: {} };

    _.mapObject(formData, (section, sectionName) => {
      _.mapObject(section, (sectionItem, sectionItemName) => {
        if (!!formData[sectionName][sectionItemName].value)
          data.batch[sectionItemName] =
            formData[sectionName][sectionItemName].value;
      });
    });

    let action = isEditMode
      ? editBatch(
          this.props.match.params.id,
          this.props.match.params.batchId,
          data
        )
      : addBatch(this.props.match.params.id, data);

    action.then(response => {
      if (!isEditMode) {
        this.props.history.replace(
          `/product-management/product/${this.props.match.params.id}/batch/${response.data.data}`
        );
      }

      this.setState({ isSnackbarOpen: true, formData: null });
      this.loadData();
    });
  };

  checkFieldErrors = (sectionName, sectionItemName) => {
    const { formData } = this.state;
    const fieldProperties = formData[sectionName][sectionItemName];

    fieldProperties.errorText = "";
    fieldProperties.error = false;

    if (
      fieldProperties.required &&
      !fieldProperties.read_only &&
      (!fieldProperties.value ||
        (_.isArray(fieldProperties.value) && _.isEmpty(fieldProperties.value)))
    ) {
      fieldProperties.errorText = "Field is required";
      fieldProperties.error = true;
    }

    if (fieldProperties.type === "DecimalField") {
      let parts = fieldProperties.value.toString().split(/[,.]/);

      if (
        fieldProperties.max_digits &&
        fieldProperties.decimal_places &&
        !parts[1] &&
        parts[0].length > fieldProperties.max_digits - fieldProperties.decimal_places
      ) {
        fieldProperties.errorText = `Ensure that there are no more than ${fieldProperties.max_digits -
          fieldProperties.decimal_places} digits before the decimal point.`;
        fieldProperties.error = true;
      }

      if (
        fieldProperties.max_digits &&
        fieldProperties.value.toString().replace(/[,.]/, "").length >
          fieldProperties.max_digits
      ) {
        fieldProperties.errorText = `Ensure that there are no more than ${fieldProperties.max_digits} digits in total`;
        fieldProperties.error = true;
      }

      if (
        fieldProperties.decimal_places &&
        parts[1] &&
        parts[1].length > fieldProperties.decimal_places
      ) {
        fieldProperties.errorText = `Ensure that there are no more than ${fieldProperties.decimal_places} decimal places.`;
        fieldProperties.error = true;
      }
    }

    this.setState({
      formData
    });
  };

  closeSnackbar = () => this.setState({ isSnackbarOpen: false });

  render() {
    return (
      <>
        <ManagementAddEditForm
          title={`${this.state.isEditMode ? "Edit" : "Add"} Batch ${
            this.state.isEditMode ? this.state.batch_number + "#" : ""
          }`}
          subcategories={this.state.subcategories}
          formData={this.state.formData}
          onFieldChange={this.handleFieldChange}
          checkFieldErrors={this.checkFieldErrors}
          onSubmit={this.handleSubmit}
        />
        <ManagementSnackbar
          variant="success"
          message={"Batch saved successfully!"}
          open={this.state.isSnackbarOpen}
          handleClose={this.closeSnackbar}
        />
      </>
    );
  }
}

export default BatchAddEdit;
