import React from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Checkbox from "@material-ui/core/Checkbox";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import { TableHeadStyles } from "./styles";
import Grid from "@material-ui/core/Grid";

class ManagementTableHead extends React.Component {
  render() {
    const {
      classes,
      onSelectAllClick,
      order,
      orderBy,
      isAllSelcted,
      isAnySelcted,
      onRequestSort,
      columns,
      dataCount
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableCell} padding="checkbox">
            <Checkbox
              disabled={!dataCount}
              indeterminate={isAnySelcted()}
              checked={isAllSelcted()}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all items" }}
            />
          </TableCell>
          {columns.map(column => (
            <TableCell
              onClick={() => onRequestSort(column.field)}
              className={classes.tableCell}
              key={column.field}
              align="left"
            >
              <Grid
                className={classes.tableCellContent}
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <span>{column.title}</span>
                <Grid className={classes.arrowWrapper} item>
                  <Grid container item direction="column" alignItems="center">
                    <ArrowDropUpIcon
                      className={clsx(classes.arrowUp, {
                        [classes.hidden]:
                          orderBy === column.field && order === "desc"
                      })}
                    />
                    <ArrowDropDownIcon
                      className={clsx({
                        [classes.hidden]:
                          orderBy === column.field && order === "asc"
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

ManagementTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  isAllSelcted: PropTypes.func.isRequired,
  isAnySelcted: PropTypes.func.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  dataCount: PropTypes.number.isRequired
};

export default withStyles(TableHeadStyles)(ManagementTableHead);
