import { instance } from "utils/dataService";

function getStrains(params = {}) {
  return instance.get("/strains/strain-list/", { params: params });
}

function getStrain(strainId) {
  return instance.get(`/strains/${strainId}/`);
}

function getFilterData() {
  return instance.get(`/filters/strain/`);
}

function addStrain(data) {
  return instance.post(`/strains/`, data);
}

function getStrainBatches(strainId) {
  return instance.get(`/strains/${strainId}/batch-list/`);
}

function getBatch(strainId, batchId) {
  return instance.get(`/strains/${strainId}/batches/${batchId}/`);
}
function addBatch(strainId, data) {
  return instance.post(`/strains/${strainId}/batches/`, data);
}
function editBatch(strainId, batchId, data) {
  return instance.put(`/strains/${strainId}/batches/${batchId}/`, data);
}

function archiveStrains(data) {
  return instance.post(`/strains/archive/`, { strain_ids: data });
}

function unarchiveStrains(data) {
  return instance.post(`/strains/unarchive/`, { strain_ids: data });
}

function editStrain(strainId, data) {
  return instance.put(`/strains/${strainId}/`, data);
}

function getStrainFieldData() {
  return instance.get("/field-data/strain/");
}

function getBatchFieldData() {
  return instance.get(`/field-data/batch/`);
}

export {
  getStrains,
  getStrain,
  getStrainFieldData,
  getBatchFieldData,
  getFilterData,
  addStrain,
  editStrain,
  getBatch,
  addBatch,
  editBatch,
  archiveStrains,
  unarchiveStrains,
  getStrainBatches
};
