export default theme => ({
  root: {
    height: "63px",
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.15)",
    position: "relative",
    zIndex: 10
  },
  marginRight: {
    marginRight: theme.spacing(2)
  },
  profileDropdown: {
    position: "relative",
    "& .MuiButton-root:hover": {
      backgroundColor: "rgba(27, 179, 161, 0.25)"
    },
    "& .profileDropdownButton": {
      height: "63px",
      minWidth: "110px",
      borderRadius: 0,
      padding: "10px",
      textTransform: "none"
    },
    "&:hover .profileDropdownContent": {
      display: "block"
    },
    "& .profileDropdownContent": {
      boxShadow: "-2px 2px 5px 0 rgba(0, 0, 0, 0.15)",
      width: "100%",
      display: "none",
      position: "absolute",
      top: "63px",
      backgroundColor: "white",
      zIndex: "20",
      right: "0",
      borderRadius: "0 0 0 10px"
    }
  },
  logoutButton: {
    width: "100%",
    height: "55px",
    borderRadius: 0,
    "& .MuiSvgIcon-root": {
      marginRight: "5px"
    }
  }
});
