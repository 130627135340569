import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import ManagementTableHead from "./managementTableHead";
import ManagementTableToolbar from "./managementTableToolbar";
import { TableStyles } from "./styles";
import _ from "underscore";

const START_PAGE = 0;

class ManagementTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "desc",
      orderBy: "",
      selected: [],
      page: START_PAGE,
      rowsPerPage: 50,
      search: ""
    };
  }

  componentDidUpdate() {
    if (!_.isEqual(this.props.selected, this.state.selected)) {
      this.setState({
        selected: this.props.selected
      });
    }
  }

  onRequestSort = property => {
    const { orderBy, order } = this.state;
    const isSameProperty = orderBy === property;
    const isDesc = isSameProperty && order === "desc";
    const isAsc = isSameProperty && order === "asc";
    this.setState({
      order: isDesc ? "asc" : "desc",
      orderBy: isAsc ? "" : property
    });

    this.props.onRequestSort &&
      this.props.onRequestSort(isAsc ? "" : (isDesc ? "" : "-") + property);
  };

  handleSelectAllClick = event => {
    const { rowId, data } = this.props;
    const { selected } = this.state;
    let newSelected = event.target.checked
      ? _.uniq(_.union(data.results, selected), false, _.property(rowId))
      : _.uniq(_.difference(selected, data.results), false, _.property(rowId));
    this.setState({
      selected: newSelected
    });
    this.props.onSelectionChange && this.props.onSelectionChange(newSelected);
  };

  handleClick = (event, row) => {
    const { rowId } = this.props;
    const { selected } = this.state;
    const isItemSelected = _.contains(
      selected,
      _.findWhere(selected, {
        [rowId]: row[rowId]
      })
    );
    let newSelected = [];

    if (isItemSelected) {
      newSelected = _.without(
        selected,
        _.findWhere(selected, {
          [rowId]: row[rowId]
        })
      );
    } else {
      newSelected = [row, ...selected];
    }

    this.setState({
      selected: newSelected
    });
    this.props.onSelectionChange && this.props.onSelectionChange(newSelected);
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage
    });
    this.props.onChangePage && this.props.onChangePage(newPage);
  };

  onSearchChange = data => {
    this.setState({
      page: START_PAGE
    });
    this.props.onSearchChange && this.props.onSearchChange(data);
  };

  renderCellValue = (column, row) =>
    column.render ? column.render(row[column.field], row) : row[column.field];

  isSelected = row => {
    const { rowId } = this.props;
    const { selected } = this.state;
    return _.contains(
      selected,
      _.findWhere(selected, {
        [rowId]: row[rowId]
      })
    );
  };
  isAllSelcted = () => {
    const { rowId, data } = this.props;
    const { selected } = this.state;
    if (!data.results) return false;

    const itemIds = data.results.map(item => item[rowId]);
    const selectedIds = selected.map(item => item[rowId]);
    return _.difference(itemIds, selectedIds).length === 0;
  };
  isAnySelcted = () => {
    const { rowId, data } = this.props;
    const { selected } = this.state;
    if (!data.results) return false;

    const itemIds = data.results.map(item => item[rowId]);
    const selectedIds = selected.map(item => item[rowId]);
    const diffLength = _.intersection(itemIds, selectedIds).length;
    return diffLength > 0 && diffLength < itemIds.length;
  };

  render() {
    const {
      classes,
      data,
      title,
      columns,
      rowId,
      actions,
      onAddClick,
      onEditClick,
      searchText,
      renderSelectionMessage
    } = this.props;
    const { rowsPerPage, orderBy, order, selected } = this.state;
    let page = _.isUndefined(this.props.page)
      ? this.state.page
      : this.props.page;

    return (
      <div>
        <ManagementTableToolbar
          title={title}
          searchText={searchText}
          renderSelectionMessage={renderSelectionMessage}
          selected={selected}
          onSearchChange={this.onSearchChange}
          actions={actions}
          onAddClick={onAddClick}
        />
        {!data.results && (
          <div className={classes.loaderWrapper}>
            <CircularProgress size={100} />
          </div>
        )}
        <div className={classes.tableWrapper}>
          <Table
            stickyHeader
            className={classes.table}
            size="medium"
            aria-label="Management Table"
          >
            <ManagementTableHead
              isAllSelcted={this.isAllSelcted}
              isAnySelcted={this.isAnySelcted}
              onEditClick={onEditClick}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.onRequestSort}
              columns={columns}
              dataCount={data.count || 0}
            />
            <TableBody>
              {!!data.results &&
                data.results.map((row, index) => {
                  const isItemSelected = this.isSelected(row);
                  const labelId = `management-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, row)}
                      role="checkbox"
                      className={classes.tableRow}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row[rowId]}
                      selected={isItemSelected}
                    >
                      <TableCell
                        className={classes.tableCell}
                        padding="checkbox"
                      >
                        <div
                          className={clsx(
                            classes.cellContent,
                            classes.checkboxCellContent
                          )}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </div>
                      </TableCell>
                      {columns.map((column, colIndex) => {
                        let isIdColumn = column.field === rowId;

                        return (
                          <TableCell
                            className={classes.tableCell}
                            key={colIndex}
                          >
                            <div
                              onClick={() =>
                                isIdColumn && onEditClick(row, row[rowId])
                              }
                              className={clsx(classes.cellContent, {
                                [classes.cellIdHighlight]: isIdColumn
                              })}
                            >
                              {this.renderCellValue(column, row)}
                            </div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          className={classes.pagination}
          rowsPerPageOptions={[50]}
          component="div"
          count={data.count || 0}
          rowsPerPage={rowsPerPage}
          page={data.count ? page : 0}
          backIconButtonProps={{
            "aria-label": "previous page"
          }}
          nextIconButtonProps={{
            "aria-label": "next page"
          }}
          onChangePage={this.handleChangePage}
        />
      </div>
    );
  }
}

export default withStyles(TableStyles)(ManagementTable);

ManagementTable.propTypes = {
  title: PropTypes.string.isRequired,
  searchText: PropTypes.string,
  columns: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  page: PropTypes.number,
  rowId: PropTypes.string.isRequired,
  actions: PropTypes.func,
  onEditClick: PropTypes.func,
  onSearchChange: PropTypes.func,
  onChangePage: PropTypes.func,
  onRequestSort: PropTypes.func,
  onSelectionChange: PropTypes.func,
  renderSelectionMessage: PropTypes.func
};
